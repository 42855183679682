$(function () {

    $('#service_selector').on('change', function () {
        var service = $(this).find(':selected');
        var clone = $('#service_table_item_template').clone();

        var image = service.data('image') ? '<a class="white-tooltip" data-toggle="tooltip" title="<img src=\'' + service.data('originalImage') + '\'>"><img src=\'' + service.data('image') + '\' class=\'service-image\'></a> ' : '';
        clone.find('.selected-service-name').html(image + service.data('name'));
        clone.attr('data-service-id', service.data('id'));
        clone.attr('data-service-name', service.data('name'));
        clone.attr('data-service-price', service.data('price'));
        clone.attr('data-service-image', service.data('image'));

        clone.find('.selected-service-id').val(service.data('id'));
        clone.addClass('service-item');

        clone.insertBefore('#service_table_total');

        $('#services_table').show(100, function() {
            $('a[data-toggle="tooltip"]').tooltip({
                animated: 'fade',
                placement: 'bottom',
                html: true
            });
        });

        clone.show();

        $('#service_selector').find(':selected').prop('selected', false);
        $('#service_selector').multiselect('refresh');
    });

    $('#services_table').on('keyup change', '.selected-service-quantity', function (e) {
        if (isNaN($(this).val())) {
            $(this).val(0);
            e.preventDefault();
            return false;
        }

        var service = $(this).closest('tr');
        var subtotal = parseInt(service.data('servicePrice')) * parseInt(service.find('.selected-service-quantity').val());

        if (isNaN(subtotal)) {
            subtotal = 0;
        }

        service.find('.selected-service-subtotal').data('subtotal', subtotal).html(subtotal + ' Ft');

        refreshWeddingServicePrice();
    });

    function refreshWeddingServicePrice() {
        var total = 0;

        $('#services_table .service-item .selected-service-subtotal').each(function () {
            var subtotal = parseInt($(this).data('subtotal'));

            if (isNaN(subtotal)) {
                return;
            }

            total += subtotal;
        });

        $('#service_table_total .amount').html(total + ' Ft');
    }

    $('#services_table').on('click', '.selected-service-delete-button', function () {
        $(this).closest('tr').remove();

        if ($('#services_table').find('.service-item').length === 0) {
            $('#services_table').hide(100);
        }

        refreshWeddingServicePrice();
    });
});