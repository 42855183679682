(function ($) {
    //http://bxslider.com/examples/auto-show-start-stop-controls

    if ($('#bxslider-home4').length > 0) {
        var slider = $('#bxslider-home4').bxSlider({
            nextText: '<i class="fa fa-angle-right"></i>',
            prevText: '<i class="fa fa-angle-left"></i>',
            auto: true,
            infiniteLoop: false,
            hideControlOnEnd: true,
            adaptiveHeight: true,
            speed: 2000,
            mode: 'fade',
            onSliderLoad: function (currentIndex) {
                $('#bxslider-home4 li').find('.caption').each(function (i) {
                    $(this).show().addClass('animated fadeInRight').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                        $(this).removeClass('fadeInRight animated');
                    });
                })
            },
            onSlideBefore: function (slideElement, oldIndex, newIndex) {
                //slideElement.find('.sl-description').hide();
                slideElement.find('.caption').each(function () {
                    $(this).hide().removeClass('animated fadeInRight');
                });

                slideElement.each(function () {
                    $(this).find('img').css('display', 'block');
                });

                $('#bxslider-home4 li').css('left', '0');
                $('#bxslider-home4 li').css('right', '0');
            },
            onSlideAfter: function (slideElement, oldIndex, newIndex) {
                //slideElement.find('.sl-description').show();
                setTimeout(function () {
                    slideElement.find('.caption').each(function () {
                        $(this).show().addClass('animated fadeInRight').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                            $(this).removeClass('fadeInRight animated');
                        });
                    });
                }, 500);

                $('#bxslider-home4 li').css('left', '0');
                $('#bxslider-home4 li').css('right', '0');
            }
        });
        //slider.reloadSlider();
    }
})(jQuery); // End of use strict