jQuery(document).ready(function ($) {
    $(".mainmenu-area").sticky({ topSpacing: 0 });

    var productsOwl = $('.product-carousel');

    productsOwl.on('initialized.owl.carousel', function () {
        $('.product-carousel').css('display', 'block');
    });

    productsOwl.owlCarousel({
        loop: true,
        nav: true,
        margin: 20,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 5,
            }
        },
        nav: true,
        navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],

    });

    var featuredProductsOwl = $('.featured-product-carousel');

    featuredProductsOwl.on('initialized.owl.carousel', function () {
        $('.featured-product-carousel').css('display', 'block');
    });

    featuredProductsOwl.owlCarousel({
        loop: false,
        nav: true,
        margin: 20,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            }
        },
        navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
    });

    /*$('.related-products-carousel').owlCarousel({
     loop: true,
     nav: true,
     margin: 20,
     responsiveClass: true,
     responsive: {
     0: {
     items: 1,
     },
     600: {
     items: 2,
     },
     1000: {
     items: 2,
     },
     1200: {
     items: 3,
     }
     },
     nav: true,
     navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
     });*/

    $('.brand-list').owlCarousel({
        loop: true,
        nav: true,
        margin: 20,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            }
        }
    });

    // Bootstrap Mobile Menu fix
    $(".navbar-nav li a").click(function () {
        //$(".navbar-collapse").removeClass('in');
    });

    $(".dropdown.mega a").click(function (e) {
        /*e.preventDefault();
         e.stopPropagation();
         return false;*/
    });

    // jQuery Scroll effect
    $('.navbar-nav li a, .scroll-to-up').bind('click', function (event) {
        /*var $anchor = $(this);
         var headerH = $('.header-area').outerHeight();
         $('html, body').stop().animate({
         scrollTop: ($($anchor.attr('href')).offset().top - headerH) + "px"
         }, 1200, 'easeInOutExpo');

         event.preventDefault();*/
    });

    // Bootstrap ScrollPSY
    $('body').scrollspy({
        target: '.navbar-collapse',
        offset: 95
    });

    $('.remove-cart-item').on('click', function () {
        var product = $(this).closest('tr');
        var cartItemId = product.data('cartItemId');

        $.ajax({
            url: App.path_cart + '/remove',
            data: {
                cartItemId: cartItemId
            },
            dataType: 'json',
            type: 'POST',

            error: function () {
                $.notify(App.translations.remove_was_fail, 'error');
            },

            success: function (data) {
                updateCartView(data);
                $.notify(App.translations.remove_was_success, 'success');
                product.remove();

                if ($('.cart .cart_item').length < 1) {
                    window.location = App.path;
                }
            }
        });
    });

    $('.product-quantity .quantity .minus').on('click', function () {
        var quantityField = $(this).closest('tr').find('.input-text.qty');
        var newQuantity = parseInt(quantityField.val()) - 1;

        console.log(newQuantity);

        if (newQuantity < 1) {
            if (confirm(App.translations.are_you_sure_to_remove_from_cart)) {
                $(this).closest('tr').find('.remove-cart-item').click();

                return;
            } else {
                return;
            }
        }

        quantityField.val(newQuantity);
        quantityField.change();
    });

    $('.product-quantity .quantity .plus').on('click', function () {
        var quantityField = $(this).closest('tr').find('.input-text.qty');
        quantityField.val(parseInt(quantityField.val()) + 1);
        quantityField.change();
    });

    $('.input-text.qty').on('change keyup', function (e) {
        console.log('.input-text.qty change');

        var product = $(this).closest('tr');
        var cartItemId = product.data('cartItemId');
        var quantity = parseInt($(this).val());

        if (!cartItemId) {
            console.log('Cannot find cartItemId');

            return;
        }

        if ((quantity) < 1) {
            if (confirm(App.translations.are_you_sure_to_remove_from_cart)) {
                $(this).closest('tr').find('.remove-cart-item').click();

                return;
            } else {
                $(this).val(1);
                e.preventDefault();
                return false;
            }
        }

        $.ajax({
            url: App.path_cart + '/update',
            data: {
                cartItemId: cartItemId,
                quantity: quantity
            },
            dataType: 'json',
            type: 'POST',

            error: function () {
                $.notify(App.translations.update_was_fail, 'error');
            },

            success: function (data) {
                updateCartView(data);
                $.notify(App.translations.update_was_success, 'success');

                product.find('.product-subtotal .amount').html((parseInt(product.data('price')) * quantity) + ' Ft');

                if (activeShippingOption) {
                    activeShippingOption.click();
                }

            }
        });
    });

    $('.add-to-cart').on('click', function () {
        var product = $(this);
        var productOptions = {};
        var quantity = 1;

        if (!product.data('productId')) {
            return;
        }

        if ($(this).closest('.cart-form').length > 0) {
            var cartForm = $(this).closest('.cart-form');
            var options = cartForm.find('.product-option');
            quantity = cartForm.find('.qty').val();

            // if (product.attr('quantity')) {
            //     quantity = product.attr('quantity');
            // }

            var hasMissingOption = false;

            options.each(function () {
                var option = $(this);
                var productOption = {};

                if (option.hasClass('required') && option.val() === '') {
                    $.notify(App.translations.please_update_following_option + option.attr('placeholder'), 'warning');
                    hasMissingOption = true;
                    return;
                }

                if (!option.data('optionId') || option.val() === '') {
                    return;
                }

                productOptions[option.data('optionId')] = option.val();
            });
        }

        if (hasMissingOption) {
            return;
        }

        $.ajax({
            url: App.path_cart + '/add',
            data: {
                productId: product.data('productId'),
                quantity: quantity,
                options: productOptions
            },
            dataType: 'html',
            type: 'POST',

            error: function () {
                $.notify(App.translations.to_cart_fail, 'error');
            },

            success: function (data) {
                $('.shopping-item').replaceWith(data);
                $.notify(App.translations.to_cart_success + '<br><a href="' + App.path_checkout + '">' + App.translations.proceed_to_checkout + '</a>', 'success');

                if (product.hasClass('buy-now')) {
                    window.location = App.path_checkout;
                }
            }
        });
    });

    $(document).on('change', '#billing-equals-shipping', function () {
        var billingEqualsShipping = $(this).prop('checked') === true;

        if (billingEqualsShipping) {
            $('.billing-fields').addClass('billing-equals-shipping');
        } else {
            $('.billing-fields').removeClass('billing-equals-shipping');
        }
    });

    var activeShippingOption;

    $(document).on('click change', '.shipping_methods .shipping_method .input-radio', function () {
        activeShippingOption = $(this);

        var shipping = $(this).data(); //freeCondition, price, id, name
        var cartTotal = $('.cart').data('total');
        var freeCondition = shipping.freeCondition;
        var discountPrice = shipping.discountPrice;
        var shippingPrice = shipping.price;
        var baseShippingCost = $('#base-shipping-cost').val();

        if (shipping.id == 1) {
            shippingPrice = baseShippingCost;
        }

        // if (freeCondition) {
        //     var operator = freeCondition.charAt(0);
        //     var amount = parseInt(freeCondition.substr(1));

        //     switch (operator) {
        //         case '<':
        //             if (amount > cartTotal) {
        //                 shippingPrice = discountPrice;
        //             }
        //             break;

        //         case '>':
        //             if (amount < cartTotal) {
        //                 shippingPrice = discountPrice;
        //             }
        //             break;
        //     }
        // }

        console.log('Shipping changed to ' + shipping.name + '(' + shippingPrice + ' Ft)');

        $('.cart .shipping-item .shipping-name').html(shipping.name);
        $('.cart .shipping-item .shipping-price').html(new Intl.NumberFormat('hu-HU').format(shippingPrice) + ' Ft');
        $('.cart .shipping-item .shipping-quantity').html(1);
        $('.cart .shipping-item .shipping-subtotal').html(new Intl.NumberFormat('hu-HU').format(shippingPrice) + ' Ft');

        $('.cart').data('shippingPrice', shippingPrice);

        cartTotal = parseFloat(cartTotal) + parseFloat(shippingPrice);

        $('.cart .checkout-summary-amount').html(new Intl.NumberFormat('hu-HU').format(cartTotal) + ' Ft');
    });

    $('#list').click(function (event) {
        event.preventDefault();
        $('#products .item').addClass('list-group-item');
        $.fn.matchHeight._update();
        $('#grid').removeClass('btn-info').addClass('btn-default');
        $('#list').removeClass('btn-default').addClass('btn-info');
    });

    $('#grid').click(function (event) {
        event.preventDefault();
        $('#products .item').removeClass('list-group-item');
        $('#products .item').addClass('grid-group-item');
        $.fn.matchHeight._update();
        $('#list').removeClass('btn-info').addClass('btn-default');
        $('#grid').removeClass('btn-default').addClass('btn-info');
    });

    jQuery(document).on('click', '.mega-dropdown', function (e) {
        e.stopPropagation();
    });

    $('#products .item').matchHeight();

    /*$.validator.setDefaults({
        ignore: ':hidden'
    });*/

    $('form#checkout').validate({
        //ignore: [':hidden'],
        ignore: '.billing-equals-shipping :input',

        errorPlacement: function (error, element) {
            if (element.closest('.shipping_methods').length > 0) {
                $.notify(App.translations.required_to_select_delivery_method, 'warning');

                error.html(App.translations.required_to_select);
                error.css('marginRight', '10px');

                $('#shipping_header').prepend(error);
            } else if (element.closest('.payment_methods').length > 0) {
                $.notify(App.translations.required_to_select_payment_method, 'warning');

                error.html(App.translations.required_to_select);
                error.css('marginRight', '10px');

                $('#payment_header').prepend(error);
            } else {
                $(element)
                    .closest("form")
                    .find("label[for='" + element.attr("id") + "']")
                    .closest('.input-group-addon').css('color', 'red');
            }
        },
        invalidHandler: function (event, validator) {
            var errors = validator.numberOfInvalids();

            if (errors) {
                var message = '' + errors + App.translations.x_field_have_error;

                $.notify(message, 'warning');
            }
        },
        highlight: function (element, errorClass, validClass) {
            $(element)
                .closest("form")
                .find("label[for='" + $(element).attr("id") + "']")
                .closest('.input-group-addon').css('color', 'red');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element)
                .closest("form")
                .find("label[for='" + $(element).attr("id") + "']")
                .closest('.input-group-addon').css('color', 'green');
        }
    });
    /*showErrors: function(errorMap, errorList) {
     $("#summary").html("Your form contains "
     + this.numberOfInvalids()
     + " errors, see details below.");
     this.defaultShowErrors();
     }*/

    window.navbarSearchError = false;

    $('form#navbar_search').validate({
        errorPlacement: function (error, element) {
            if (window.navbarSearchError === true) return;

            $.notify(App.translations.min_2_chars, 'error');

            window.navbarSearchError = true;
        },
    });

    $('form#wedding_form').validate({});


    $('.fancybox').fancybox({
        afterShow: function () {
            /*<span class="fa-stack fa-lg">
             <i class="fa fa-square fa-stack-2x"></i>
             <i class="fa fa-terminal fa-stack-1x fa-inverse"></i>
             </span>*/
            $('<div class="fancybox-fullsize" title="Megjelenítés teljes méretben"><span class="fa-stack fa-lg"><i class="fa fa-circle fa-stack-2x "></i><i class="fa fa-expand fa-stack-1x fa-inverse"></i></span></div>').appendTo(this.skin).click(function () {
                window.open($.fancybox.current.href);
            });
        }
    });

    $('.wedding-option-select').multiselect({
        includeSelectAllOption: true,
        enableCaseInsensitiveFiltering: true,
        selectAllText: App.translations.select_all,
        disabledText: '',
        buttonText: function (options, select) {
            if (options.length > 0) {
                var optionTexts = options.map(function () {
                    return $(this).text();
                }).get();

                return optionTexts.slice(0, 3).join(', ') + (optionTexts.length > 3 ? '...' : '');
            } else {
                return select.attr('placeholder');
            }
        },
        nSelectedText: '',
        nonSelectedText: '',
        allSelectedText: '',
        filterPlaceholder: App.translations.filter,
        enableHTML: true,
        optionLabel: function (element) {
            if (!$(element).attr('data-image')) {
                return $(element).text();
            }

            return '<a class="white-tooltip" data-toggle="tooltip" title="<img src=\'' + $(element).attr('data-original-image') + '\'>"><img src=\'' + $(element).attr('data-image') + '\' class=\'select-service-image\' height=\'50\'></a> ' + $(element).text();
        },
    });

    $('a[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        placement: 'bottom',
        html: true
    });

    // http://davidstutz.github.io/bootstrap-multiselect/#configuration-options-onInitialized
    $('.search-by-tag').multiselect({
        enableCaseInsensitiveFiltering: true,
        onInitialized: function (select, container) {
            $('#home-filter-container').css('opacity', '1');
        },
        maxHeight: 350,
        includeSelectAllOption: false,
        enableFiltering: true,
        selectAllText: App.translations.select_all,
        disabledText: '',
        buttonText: function (options, select) {
            if (options.length > 0) {
                var optionTexts = options.map(function () {
                    return $(this).closest('optgroup').attr('label') + ': ' + $(this).text() + '';
                }).get();

                return optionTexts.slice(0, 3).join(', ') + (optionTexts.length > 3 ? '...' : '');
            } else {
                return select.attr('placeholder');
            }
        },
        nSelectedText: '',
        nonSelectedText: '',
        allSelectedText: '',
        filterPlaceholder: App.translations.filter
    });

    //http://eonasdan.github.io/bootstrap-datetimepicker/Options/#focusonshow
    /*var datetimepickerSettings = {
     locale: 'hu',
     format: 'YYYY-MM-DD',
     tooltips: {
     today: "Mai nap kiválasztása",
     clear: "Kijelölés megszüntetése",
     close: "Becsukás",
     selectMonth: "Hónap választása",
     prevMonth: "Előző hónap",
     nextMonth: "Következő hónap",
     selectYear: "Év választása",
     prevYear: "Előző év",
     nextYear: "Következő év",
     selectDecade: "Évtized",
     prevDecade: "Előző évtized",
     nextDecade: "Következő évtized",
     prevCentury: "Előző évszázad",
     nextCentury: "Következő évszázad",
     pickHour: "Óra kiválasztása",
     incrementHour: "Óra növelése",
     decrementHour: "Óra csökkentése",
     pickMinute: "Perc kiválasztása",
     incrementMinute: "Perc növelése",
     decrementMinute: "Perc csökkentése",
     pickSecond: "Másodperc kiválasztása",
     incrementSecond: "Máso2016-05-19dperc növelése",
     decrementSecond: "Másodperc csökkentése",
     togglePeriod: "Periódus váltása",
     selectTime: "Idő választása"
     }
     };*/

    if ($('.datepicker').length > 0) {
        $('.datepicker').datetimepicker($.extend({}, datetimepickerSettings, {}));
    }

    if ($('.product-option-date').length > 0) {
        $('.product-option-date').datetimepicker($.extend({}, datetimepickerSettings, {}));
    }

    if ($('.datepicker-no-weekend').length > 0) {
        $('.datepicker-no-weekend').datetimepicker($.extend({}, datetimepickerSettings, {
            daysOfWeekDisabled: [0, 6]
        }));
    }

    /*$('#delivery_date').datetimepicker($.extend({}, datetimepickerSettings, {
     //daysOfWeekDisabled: [0, 6],
     minDate: $('#delivery_date_text').data('minimumFulfilmentTime'),
     disabledDates: [
     //moment("2016-04-10", "YYYY-MM-DD"),
     ],
     format: 'YYYY-MM-DD HH:mm',
     defaultDate: $('#delivery_date_text').data('minimumFulfilmentTime'),
     }));*/

    $('.sidebar-order-form .ordering').on('click', function () {
        var form = $(this).closest('.sidebar-order-form');
        var order = $(this).data();

        $('#order_direction').val(order['direction']);
        $('#order_field').val(order['field']);

        form.submit();
    });


    $('.payment_methods .payment_method').on('click', function () {
        $('.payment_methods .payment_method .payment_box_hidden').hide(0);
        $(this).find('.payment_box_hidden').show(0);
    });

    $('select.product-option').on('change', function () {
        var selectedOptionItem = $(this).find('option:selected');

        $('.product-inner-price .price').html(selectedOptionItem.data('productPrice'));

        if (selectedOptionItem.data('formattedImage')) {
            var $img = '<a class="white-tooltip" data-toggle="tooltip" title="<img src=\'' + selectedOptionItem.data('formattedOriginalImage') + '\'>"><img src=\'' + selectedOptionItem.data('formattedImage') + '\' class=\'select-service-image\' style=\'height: 34px !important;\'></a>';

            $(this).closest('div.row').find('.product-option-item-image-container').empty().append($img);

            $('a.white-tooltip').tooltip({
                animated: 'fade',
                placement: 'left',
                html: true
            });
        } else {
            $(this).closest('div.row').find('.product-option-item-image-container').empty();
        }
        //data-formatted-image, data-formatted-original-image
        //product-option-item-image
    });
});

function updateCartView(data) {
    data.total = parseInt(data.total);
    data.shipping = parseInt(data.shipping);

    $('.cart').data('total', (data.total));

    if ($('.cart').data('shippingPrice')) {
        $('.cart').data('shippingPrice', (data.shipping));

        $('#base-shipping-cost').val(data.shipping);

        data.total += data.shipping;
    }

    $('.checkout-summary-amount').html(data.total + ' Ft');
}

//http://eonasdan.github.io/bootstrap-datetimepicker/Options/#focusonshow
var datetimepickerSettings = {
    locale: App.locale
};

if (App.locale === 'hu') {
    datetimepickerSettings.format = 'YYYY-MM-DD';
    datetimepickerSettings.tooltips = {
        today: "Mai nap kiválasztása",
        clear: "Kijelölés megszüntetése",
        close: "Becsukás",
        selectMonth: "Hónap választása",
        prevMonth: "Előző hónap",
        nextMonth: "Következő hónap",
        selectYear: "Év választása",
        prevYear: "Előző év",
        nextYear: "Következő év",
        selectDecade: "Évtized",
        prevDecade: "Előző évtized",
        nextDecade: "Következő évtized",
        prevCentury: "Előző évszázad",
        nextCentury: "Következő évszázad",
        pickHour: "Óra kiválasztása",
        incrementHour: "Óra növelése",
        decrementHour: "Óra csökkentése",
        pickMinute: "Perc kiválasztása",
        incrementMinute: "Perc növelése",
        decrementMinute: "Perc csökkentése",
        pickSecond: "Másodperc kiválasztása",
        incrementSecond: "Máso2016-05-19dperc növelése",
        decrementSecond: "Másodperc csökkentése",
        togglePeriod: "Periódus váltása",
        selectTime: "Idő választása"
    };

}


$(function () {
    $(window).on('scroll', function () {
        if ($('.slide-piece').length < 1) {
            return;
        }

        var backgroundPosition = $('.slide-piece').css('backgroundPosition').split(' ');
        var frontPosition = $('.slide-piece-front').css('backgroundPosition').split(' ');
        var backgroundX = backgroundPosition[0], backgroundY = (((parseFloat($(document).scrollTop()) / 10) + 0) * -1); //370 -> 390
        var frontBottom = parseInt(((parseFloat($(document).scrollTop()) / 4) - 5)) + '%';
        var frontOpacity = Math.abs(parseInt(100 - ((parseFloat($(document).scrollTop()) / 3))) / 100);

        if (backgroundY < -20) {
            backgroundY = -20;
        }

        $('.slide-piece').css('backgroundPosition', backgroundX + ' ' + backgroundY + 'px');
        $('.slide-piece-front').css('bottom', frontBottom);
        $('.slide-piece-front').css('opacity', frontOpacity);
    });
});

if ($('.checkout-wrapper').length > 0) {
    /*if ($('#delivery_date').length > 0) {
     $('#delivery_date').datetimepicker($.extend({}, datetimepickerSettings, {
     //daysOfWeekDisabled: [0, 6],
     minDate: $('#delivery_date_text').data('minimumFulfilmentTime'),
     format: 'YYYY-MM-DD HH:mm',
     defaultDate: $('#delivery_date_text').data('minimumFulfilmentTime'),
     }));
     }*/

    /*$("#delivery_date").on("dp.change", function (e) {
     $.ajax({
     url: App.path_checkout + '/canDeliver',
     data: {
     at: e.date.format('YYYY-MM-DD HH:mm')
     },
     dataType: 'json',
     type: 'POST',

     error: function () {
     $.notify('Sikertelen lekérdezés.', 'error');
     },

     success: function (data) {
     //if (e.date.format('YYYY-MM-DD') == moment('2016-07-07').format('YYYY-MM-DD')) {
     if (data.result === false) {
     $.notify('A legközelebbi lehetséges időpontot kiválasztottuk az Ön számára a megadott időhöz képest.', 'warning');
     $("#delivery_date").data('DateTimePicker').date(moment(data.nextTime));
     } else {
     //$("#delivery_date").data('DateTimePicker').date(e.oldDate);
     }
     }
     });
     });*/
}

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

$.notify.addStyle('cookielaw', {
    html: "<div>" +
        "<div class='clearfix'>" +
        "<div class='title' data-notify-html='title'/>" +
        "<div class='buttons input-group'>" +
        "" +
        "<button class='yes btn btn-info form-control' data-notify-text='button'></button>" +
        "</div>" +
        "</div>" +
        "</div>"
});

$(document).on('click', '.notifyjs-cookielaw-base .yes', function () {
    createCookie("cookie-law-ok", 1, 365);

    $(this).trigger('notify-hide');
});

if (!readCookie("cookie-law-ok")) {
    $.notify({
        title: App.translations.cookie_law + ' <a href="' + App.path_data_security_info + '" title="' + App.translations.data_privacy + '" target="_blank">' + App.translations.information + '</a>',
        button: 'OK'
    }, {
        style: 'cookielaw',
        autoHide: false,
        clickToHide: false,
        position: 'bottom left'
    });
}

window.throttles = [];

function setFieldsByPostcode(cityFieldSelector, countyFieldSelector) {
    if (parseInt($(this).val()) < 1000) {
        return;
    }

    window.throttles[cityFieldSelector + countyFieldSelector] = setTimeout(function () {
        $.ajax({
            url: App.path + '/commonData/cityByPostcode',
            data: {
                postcode: parseInt($(this).val())
            },
            dataType: 'json',
            type: 'POST',

            success: function (data, textStatus, xhr) {
                $(cityFieldSelector).val(data.city);
                $(countyFieldSelector).val(data.county);
            }
        });
    }.bind(this, cityFieldSelector, countyFieldSelector), 150);
}

$(function () {
    $('#checkout').on('keyup change', '#shipping_postcode', setFieldsByPostcode.bind($('#shipping_postcode'), '#shipping_city', '#shipping_state'));
    $('#checkout').on('keyup change', '#billing_postcode', setFieldsByPostcode.bind($('#billing_postcode'), '#billing_city', '#billing_state'));
});
